import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { publicRoutes, bookingRoutes, loginRoutes, authRoutes } from './Router/index';
import { IndexLayout, LoginLayout, LoggedLayout } from './Layout/index';
import { AuthMiddleware, BookingMiddleware } from './Router/auth';
import LoadScriptWrapper from './Component/Booking/LoadScriptWrapper';
import PageNotFound from './Component/pageNotFound';
import { Suspense } from 'react';

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <LoadScriptWrapper>
          <Router>
            <Routes>
              {publicRoutes.map((publicRoute, index) => (
                <Route exact path={publicRoute.path} element={<IndexLayout>{publicRoute.element}</IndexLayout>} key={index} />
              ))}
              {bookingRoutes.map((bookingRoute, index) => (
                <Route exact path={bookingRoute.path} element={<BookingMiddleware><IndexLayout>{bookingRoute.element}</IndexLayout></BookingMiddleware>} key={index} />
              ))}
              {loginRoutes.map((loginRoutes, index) => (
                <Route exact path={loginRoutes.path} element={<LoginLayout>{loginRoutes.element}</LoginLayout>} key={index} />
              ))}
              {authRoutes.map((authRoute, index) => (
                <Route exact path={authRoute.path} element={<AuthMiddleware><LoggedLayout>{authRoute.element}</LoggedLayout></AuthMiddleware>} key={index} />
              ))}
              <Route path="*" element={<IndexLayout><PageNotFound/></IndexLayout>}/>
            </Routes>
          </Router>
        </LoadScriptWrapper>
      </Suspense>
    </>
  );
}

export default App;

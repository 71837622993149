import React, { Suspense } from 'react';
// import Index from '../Component';
const Index = React.lazy(() => import('../Component'));
const About = React.lazy(() => import('../Component/about'));
const Login = React.lazy(() => import('../Component/Authentication/login'));
const Register = React.lazy(() => import('../Component/Authentication/register'));
const TipsPending = React.lazy(() => import('../Component/Blog/tips_pending'));
const ConfirmBooking = React.lazy(() => import('../Component/Booking/confirmbooking'));
const InitiateBooking = React.lazy(() => import('../Component/Booking/initiatebooking'));
const ContactUs = React.lazy(() => import('../Component/contactus'));
const ChangePassword = React.lazy(() => import('../Component/Customer/changePassword'));
const MybookingDeatils = React.lazy(() => import('../Component/Customer/myBookingDetails'));
const MyBookingLists = React.lazy(() => import('../Component/Customer/myBookingLists'));
const Settings = React.lazy(() => import('../Component/Customer/settings'));
const Faq = React.lazy(() => import('../Component/faq'));
const HelpSupport = React.lazy(() => import('../Component/helpSupport'));
const Resetpassword = React.lazy(() => import('../Component/resetpassword'));
const Services = React.lazy(() => import('../Component/services'));
const BudgetMoving = React.lazy(() => import('../Component/ServicesWeOffer/budgetmoving'));
const DisposalMoving = React.lazy(() => import('../Component/ServicesWeOffer/disposal'));
const ManpowerMoving = React.lazy(() => import('../Component/ServicesWeOffer/manpower'));
const PremimumMoving = React.lazy(() => import('../Component/ServicesWeOffer/premium'));
const Privacy = React.lazy(() => import('../Terms/privacy'));
const Terms = React.lazy(() => import('../Terms/terms'));
const HouseMoving = React.lazy(() => import('../Component/Blog/house_moving'));
const TipsPindha = React.lazy(() => import('../Component/Blog/tips_pindha'));
const PindhaRumha = React.lazy(() => import('../Component/Blog/pindha_rumha'));
const DosDont = React.lazy(() => import('../Component/Blog/dos_dont'));

const publicRoutes = [
  { path: '/', element: <Suspense fallback={<div>Loading...</div>}><Index /></Suspense> },
  { path: '/help-support', element: <Suspense fallback={<div>Loading...</div>}><HelpSupport /></Suspense> },
  { path: '/services', element: <Suspense fallback={<div>Loading...</div>}><Services /></Suspense> },
  { path: '/about', element: <Suspense fallback={<div>Loading...</div>}><About /></Suspense> },
  { path: '/contact-us', element: <Suspense fallback={<div>Loading...</div>}><ContactUs /></Suspense> },
  { path: '/terms', element: <Suspense fallback={<div>Loading...</div>}><Terms /></Suspense> },
  { path: '/privacy', element: <Suspense fallback={<div>Loading...</div>}><Privacy /></Suspense> },
  { path: '/resetpassword', element: <Suspense fallback={<div>Loading...</div>}><Resetpassword /></Suspense> },
  { path: '/faq', element: <Suspense fallback={<div>Loading...</div>}><Faq /></Suspense> },
  { path: '/budget', element: <Suspense fallback={<div>Loading...</div>}><BudgetMoving /></Suspense> },
  { path: '/premimum', element: <Suspense fallback={<div>Loading...</div>}><PremimumMoving /></Suspense> },
  { path: '/disposal', element: <Suspense fallback={<div>Loading...</div>}><DisposalMoving /></Suspense> },
  { path: '/manpower', element: <Suspense fallback={<div>Loading...</div>}><ManpowerMoving /></Suspense> },
  { path: '/tipsPending', element: <Suspense fallback={<div>Loading...</div>}><TipsPending /></Suspense> },
  { path: '/houseMoving', element: <Suspense fallback={<div>Loading...</div>}><HouseMoving /></Suspense> },
  { path: '/tipsPindha', element: <Suspense fallback={<div>Loading...</div>}><TipsPindha /></Suspense> },
  { path: '/pindhaRumha', element: <Suspense fallback={<div>Loading...</div>}><PindhaRumha /></Suspense> },
  { path: '/dosdont', element: <Suspense fallback={<div>Loading...</div>}><DosDont /></Suspense> },
]

const bookingRoutes = [
  { path: '/initiate-booking', element: <Suspense fallback={<div>Loading...</div>}><InitiateBooking /></Suspense> },
  { path: '/confirm-booking', element: <Suspense fallback={<div>Loading...</div>}><ConfirmBooking /></Suspense> }
]

const loginRoutes = [
  { path: '/login', element: <Suspense fallback={<div>Loading...</div>}><Login /></Suspense> },
  { path: '/register', element: <Suspense fallback={<div>Loading...</div>}><Register /></Suspense> }
]

const authRoutes = [
  { path: '/my-bookings', element: <Suspense fallback={<div>Loading...</div>}><MyBookingLists /></Suspense> },
  { path: '/mybookingdetails/:enBookingId', element: <Suspense fallback={<div>Loading...</div>}><MybookingDeatils /></Suspense> },
  { path: '/settings', element: <Suspense fallback={<div>Loading...</div>}><Settings /></Suspense> },
  { path: '/change-password', element: <Suspense fallback={<div>Loading...</div>}><ChangePassword /></Suspense> },
]

export { authRoutes, bookingRoutes, loginRoutes, publicRoutes };

